import React, { useEffect, useState } from 'react';

import '../scss/main.scss';

import Seo from '../components/inc/seo';
import Layout from '../components/layout';

import Header from '../components/header';
import Footer from '../components/footer';

import ViewerScene from '../components/tool3d';

const Tool3d = () => {
  const availableFeltColors = [
    'red',
    'green',
    'green2',
    'purple',
    'black',
    'white',
  ];
  const availableMetals = ['silver', 'gold'];

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const base = 'https://store.gradivari.com/';
  const [metal, setMetal] = useState('gold');
  const [feltColor, setFeltColor] = useState('red');
  const [href, setHref] = useState(
    `${base}product/saxmax/1/?felt=${feltColor}&metal=${metal}/`
  );

  useEffect(() => {
    setHref(
      `${base}product/saxmax/1/?felt=${capitalizeFirstLetter(
        feltColor
      )}&metal=${capitalizeFirstLetter(metal)}/`
    );
  }, [metal, feltColor]);
  return (
    <>
      <Seo />
      <Header pageNotFound></Header>
      <main className='page404 customizeYourSax'>
        <Layout>
          <div className='pageNotFoundContent'>
            <h1 className='title'>Customize your SaxMax</h1>

            <div className='filtersWrap'>
              <div className='filterItem'>
                <p className='filterLabel'>Material colour:</p>
                <div className='filterbuttonWrap'>
                  {availableMetals.map((metalColour, index) => {
                    const isSelected = metal === metalColour;
                    return (
                      <button
                        className={`filterButton ${
                          isSelected ? 'activeBtn' : ''
                        }`}
                        onClick={() => setMetal(metalColour)}
                        key={index}
                      >
                        {metalColour}
                      </button>
                    );
                  })}
                </div>
              </div>

              <div className='filterItem'>
                <p className='filterLabel'>Felt colour:</p>
                <div className='filterbuttonWrap'>
                  {availableFeltColors.map((names, index) => {
                    const isSelected = names === feltColor;
                    return (
                      <button
                        className={`filterButton ${
                          isSelected ? 'activeBtn' : ''
                        }`}
                        onClick={() => setFeltColor(names)}
                        key={index}
                      >
                        {names}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className='toolWrapper'>
              <ViewerScene
                width={1200}
                height={800}
                metalColor={metal}
                feltColor={feltColor}
              />
            </div>

            <div className='buttonContainer'>
              <a
                style={{
                  margin: '0 auto',
                  boxShadow: '0 2px 12px 5px rgb(2 20 39 / 0.2)',
                }}
                className='btn'
                href={href}
              >
                Buy it!
              </a>
            </div>
          </div>
        </Layout>
      </main>
      <Footer pageNotFound />
    </>
  );
};

export default Tool3d;
